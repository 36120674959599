import { allCoupon, forgetPass } from "./Apicalls";

function ApiEndPoints(name: string): string {
  const list: { [key: string]: string } = {
    login: "api/user/auth/login",
    signup: "api/user/auth/signup",
    logout: "api/user/auth/logout",
    postProduct: "/api/product/",
    getAllproduct: "api/product",
    updateProduct: "api/product",
    getproduct: "api/product",
    deleteProduct: "api/product",
    addToFreebies: "api/product",
    fetchCustomers: "api/user",
    getCustomers: "api/user",
    allCustomer: "api/user",
    fetchTransaction: "api/transaction",
    allTransaction: "api/transaction",
    getTransaction: "api/transaction",
    fetchCustomorder: "api/customOrder",
    allCustomorder: "api/customOrder",
    getCustomorder: "api/customOrder",
    allCoupon: "api/coupon",
    fetchCoupon: "api/coupon",
    getCoupon: "api/coupon",
    updateBanner: "api/heroPage/",
    getallbanner: "api/heroPage",
    allbanner: "api/heroPage",
    getbanner: "api/heroPage",
    postBanner: "api/heroPage",
   getReport:"api/report",
   createSubAdmin:"api/subadmin/auth/create",
   getAllsubAdmin:"api/subadmin",
   subadminLogin:"api/subadmin/auth/login",
   forgetPass:"api/user/auth/forgotPassword",
   getSubadminme:"api/subadmin/getMe",
   postCustomBanner:"api/customBanner",
   bulkupdateProduct:"api/product/bulkUpdate",
   getReview:"api/review",
   tag:"api/tag",
   addtags:"api/productTag"
  };

  return list[name];
}

export default ApiEndPoints;
