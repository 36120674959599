import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Routing from "./Route/Routing";
import 'primeicons/primeicons.css';
import { PrimeReactProvider } from 'primereact/api';
import 'primeflex/primeflex.css';  
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
function App() {
  return (
    <>
       <PrimeReactProvider>
      <Routing />
      </PrimeReactProvider>
    </>
  );
}

export default App;
