import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Input, message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { API_URL } from "../Api/Config";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function Addsub() {
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    roleName: "",
    accessTabs: [],
    isActive: true,
  });

  // Handle checkbox change
  const handleCheckboxChange = (tabName: string, isChecked: boolean) => {
    setData((prevData:any) => {
      const updatedAccessTabs = isChecked
        ? [...prevData.accessTabs, tabName] // Add tabName if checked
        : prevData.accessTabs.filter((tab:any) => tab !== tabName); // Remove tabName if unchecked

      return { ...prevData, accessTabs: updatedAccessTabs };
    });
  };

  const getAuthToken = () => {
    return localStorage.getItem("dashtoken");
  };
  
  const getHeaders = () => {
    const token = getAuthToken();
    // console.log(token,"token");
    return {
      "Content-Type": "application/json",
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    };
  };

  const navigate=useNavigate()
  // Handle Signup
  const handleSignup = async () => {
    try {
      await axios.post(
        `${API_URL}api/subadmin/auth/create`,
        data,
        {
          headers: getHeaders()
        }
      );
      
      message.success("SubAdmin created successfully");
      navigate("/sub-admin")
    } catch (error: any) {
      message.error(error.response?.data?.message || "An error occurred");
    }
  };

  return (
    <section className="py-4">
      <Container>
        <div>
          <h2 className="title">
            <Link to="/sub-admin" className="link">
              <i className="fi fi-bs-less-than icon"></i>
            </Link>
            &nbsp; Add Sub Admin
          </h2>
          <hr />
          <div className="add-sub lg-3 md-6 sm-6">
            <label className="label py-2">Admin Name</label>
            <Input
              placeholder=""
              onChange={(e) => setData({ ...data, name: e.target.value })}
            />
            <label className="label py-2">Admin Roll</label>
            <Input
              placeholder=""
              onChange={(e) => setData({ ...data, roleName: e.target.value })}
            />
            <label className="label py-2">Mail id</label>
            <Input
              placeholder=""
              type="email"
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
            <label className="label py-2">Password</label>
            <Input.Password
              placeholder=""
              onChange={(e) => setData({ ...data, password: e.target.value })}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </div>

          <label className="label py-3">Admin Access</label>
          <div className="combine-check">
            <div className="checkbox-combine">
              {["Home", "Product", "Custom Order", "Freebies", "New Product", "Transaction", "Banners", "Sales Report"].map((item) => (
                <div className="d-flex justify-content-center align-items-center" key={item}>
                  <Checkbox
                    {...label}
                    size="small"
                    onChange={(e) => handleCheckboxChange(item, e.target.checked)}
                  />
                  <label className="checkbox-item">{item}</label>
                </div>
              ))}
            </div>
            <div className="checkbox-combine-1">
              {["Admin Access", "Customer","Sub Admin"].map((item) => (
                <div className="d-flex justify-content-center align-items-center" key={item}>
                  <Checkbox
                    {...label}
                    size="small"
                    onChange={(e) => handleCheckboxChange(item, e.target.checked)}
                  />
                  <label className="checkbox-item">{item}</label>
                </div>
              ))}
            </div>
          </div>

          <div className="cancel-but">
            <div className="sub-but but-1 pt-3">Cancel</div>
            <button className="but-2" onClick={handleSignup}>Create account</button>
          </div>
        </div>
      </Container>
    </section>
  );
}
