import { Button } from "@mui/material";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { MdArrowBack } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { Editor } from "primereact/editor";
import axios from "axios";
import { API_URL } from "../Api/Config";
import ApiEndPoints from "../Api/Apiendpoints";
import { forgetPass } from "../Api/Apicalls";
import { message } from "antd";

const Addnewtags = () => {
    const [data,setData]=useState({
        tagName:"",
        tagContent:"",
        metaTitle:"",
        metaContent:"",
        date:new Date()
    })

    const [messageApi, contextHolder] = message.useMessage();

    const successmessage = (content: string) => {
      messageApi.open({
        type: "success",
        content: content,
      });
    };
  
    const errormessage = (content: string) => {
      messageApi.open({
        type: "error",
        content: content,
      });
    };

    const navigate=useNavigate()
    const handleaddtags=async()=>{
        if(data.tagName!==""||data.tagContent!==""||data.metaTitle!==""||data.metaContent!==""){
            try {
                const response = await forgetPass(ApiEndPoints("addtags"),data);
                setData({
                    tagName:"",
                    tagContent:"",
                    metaTitle:"",
                    metaContent:"",
                    date:new Date()
                })
                successmessage("Added successfully")
                navigate("/tags")
              } catch (error:any) {
                console.error("Error fetching reviews:", error);
                errormessage(error)
              }
        }else{
            errormessage("All fields required !")
        }
     
    }
  return (
    <>
    {contextHolder}
      <section className="py-2">
        <Container>
          <div>
            <Link to="/tags" style={{ color: "#000", textDecoration: "none" }}>
              <span style={{ fontSize: "20px", fontWeight: 500 }}>
                <MdArrowBack />
                Tags
              </span>
            </Link>
          </div>
          <div className="tag-inbox">
            <label>Tag Name <span style={{color:"red"}}>*</span></label>
            <br />
            <input value={data.tagName} type="text" onChange={(e)=>setData({...data,tagName:e.target.value})}/>
            <br />
            <label>Tag Content <span style={{color:"red"}}>*</span></label>
            <br />
            <Editor
              value={data.tagContent || ""}
              onTextChange={(e) => e && setData({...data,tagContent:e.htmlValue || ""})}
              style={{
                height: "320px",
                lineHeight: "normal",
                width: "100%",
                wordWrap: "break-word",
              }}
            />
            <label>Meta Title <span style={{color:"red"}}>*</span></label>
            <br />
            <input type="text"  value={data.metaTitle}  onChange={(e)=>setData({...data,metaTitle:e.target.value})}/>
            <br />
            <label>Meta Content <span style={{color:"red"}}>*</span></label>
            <br />
            <textarea  value={data.metaContent} onChange={(e)=>setData({...data,metaContent:e.target.value})}></textarea>
            <div className="text-center py-3">
              <Button variant="contained" className="addtagbtn" onClick={handleaddtags}>Save</Button>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Addnewtags;
