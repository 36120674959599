import React from 'react'
import { Container } from 'react-bootstrap'
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Header = () => {
    return (
        <>
            <section className='header'>
                <Container>
                    <div className='text-end'>
                        {/* <CircleNotificationsIcon className='me-2 head-icon'/> */}
                        {/* <AccountCircleIcon className='mx-2 head-icon'/> */}
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Header
