import { Button } from "@mui/material";
import { Input, message } from "antd";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { IoMdPerson } from "react-icons/io";
import { Link } from "react-router-dom";
import ApiEndPoints from "../Api/Apiendpoints";
import { forgetPass } from "../Api/Apicalls";

const ForgetPass = () => {

  const [email,setEmail]=useState("")
  const handleChange = (e:any) => {
    setEmail(e.target.value);
  };
  

  const handleReset=async()=>{
      try {
        const response = await forgetPass(ApiEndPoints("forgetPass"),{email:email});
        message.success("Check your mail for reset password")
      } catch (error:any) {
        console.error(error);
        message.error(error.response.message)
      }
  
  }
    return (
        <>
             <section>
        <Container>
          <div className="row" style={{ height: "100vh" }}>
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
              <div>
                <img
                  className="img-fluid"
                  width={"400px"}
                  src="/assets/forget.png"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
              <div className="py-3 login-box">
                <h2 className="title text-center">Welcome</h2>
                <p className=" text-center">
                Reset Your Password
                </p>
                <label className="label py-2">Email id</label>
                <br />
                <Input
                  size="large"
                  suffix={<IoMdPerson />}
                  placeholder="Enter your email id"
                  style={{ height: "45px", width: "100%" }}
                  value={email}
                  onChange={handleChange}
                />
                <div className="text-center log-btn pt-4 pb-2">
                    <Button variant="contained" onClick={handleReset}>Reset</Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
        </>
    )
}

export default ForgetPass
