import { message, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { allbanner, deleteBanner } from "../Api/Apicalls";
import ApiEndPoints from "../Api/Apiendpoints";
import { MdOutlineDeleteForever } from "react-icons/md";
import { Box, Modal } from "@mui/material";
import { render } from "@testing-library/react";
import { IoIosCloseCircleOutline } from "react-icons/io";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const Review = () => {
  const [review, setReview] = useState<any[]>([]);
  const [selectedReview, setSelectedReview] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = (review: any) => {
    setSelectedReview(review);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: string) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };

  const errormessage = (content: string) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };

  const getAllreview = async () => {
    try {
      const response = await allbanner(ApiEndPoints("getReview"));
      setReview(response.reviews);
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  const handleDeleteReview = async (id: any) => {
    try {
      await deleteBanner(ApiEndPoints("getReview"), id);
      await getAllreview();
      successmessage("Review deleted successfully!");
    } catch (error) {
      errormessage("Failed to delete!");
    }
  };

  const truncateText = (text: any, maxLength: any) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  useEffect(() => {
    getAllreview();
  }, []);

  // Define columns for the Table
  const columns = [
    {
      title: "Product Name",
      dataIndex: ["product", "name"],
      key: "productName",
    },
    {
      title: "Name",
      dataIndex: ["user", "name"],
      key: "userName",
    },
    {
      title: "Email",
      dataIndex: ["user", "email"],
      key: "email",
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
    },
    {
      title: "Review",
      dataIndex: "review",
      key: "review",
      render: (review: any) => (
        <span onClick={() => handleOpen(review)} style={{ cursor: "pointer" }}>
          {" "}
          {truncateText(review, 20)}
        </span>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date: string) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Action",
      dataIndex: "date",
      key: "date",
      render: (date: string, record: any) => (
        <MdOutlineDeleteForever
          onClick={() => handleDeleteReview(record._id)}
          style={{ fontSize: "22px", cursor: "pointer", color: "red" }}
        />
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <section className="py-4">
        <Container>
          <div>
            <h2 className="title">Review</h2>
          </div>
          <div>
            <Table dataSource={review} columns={columns} rowKey="_id" />
          </div>

          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="d-flex justify-content-between align-items-center">
                <h3 style={{ fontSize: "20px", fontWeight: 600 }}>Review</h3>
                <IoIosCloseCircleOutline
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  onClick={handleClose}
                />
              </div>
              <p>{selectedReview}</p>
            </Box>
          </Modal>
        </Container>
      </section>
    </>
  );
};

export default Review;
