import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { BiDownArrowAlt, BiUpArrowAlt } from "react-icons/bi";
import { allCoupon, allTransaction } from "../Api/Apicalls";
import ApiEndPoints from "../Api/Apiendpoints";
import moment from "moment";
import { message } from "antd";
import { API_URL } from "../Api/Config";

const Home = () => {
  const [productDatas, setProductDatas] = useState<any[]>([]);
  const [ogDatas, setOgDatas] = useState<any[]>([]);
  const [todayEarnings, setTodayEarnings] = useState<number>(0);
  const [isCoupon, setIsCoupon] = useState<string>("");
  const [currentMonthReport, setCurrentMonthReport] = useState<{ transactionsCount: number, revenue: number,usersCreated:number }>({ transactionsCount: 0, revenue: 0,usersCreated:0 });
  const [previousMonthReport, setPreviousMonthReport] = useState<{ transactionsCount: number, revenue: number,usersCreated:number }>({ transactionsCount: 0, revenue: 0,usersCreated:0 });

  const fetchTransaction = async () => {
    try {
      const firstresponse = await allTransaction(ApiEndPoints("getTransaction"));
      const filterTodayOrder = firstresponse.transactions.filter((val: any) => 
        moment(val.date).isSame(moment(), 'day')
      );
      const paidData=filterTodayOrder.filter((val:any)=>val.isPaid)
      setProductDatas(paidData.slice(-5));
      setOgDatas(paidData);

      // Calculate today's earnings
      const todayEarnings = paidData.reduce((total: number, transaction: any) => {
        return total + (parseFloat(transaction.amount) || 0);
      }, 0);

      setTodayEarnings(todayEarnings);

      await fetchCoupon();
      await fetchReport();
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  const fetchCoupon = async () => {
    try {
      const response = await allCoupon(ApiEndPoints("getCoupon"));
      if (response.coupons.length > 0) {
        const latestCoupon = response.coupons[response.coupons.length - 1];
        setIsCoupon(latestCoupon.image);
      } else {
        message.error("No coupon found!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchReport = async () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1; 

    try {
      // Fetch current month report
      const currentMonthUrl = `${ApiEndPoints("getReport")}?year=${year}&month=${month}`;
      const currentMonthResponse = await allCoupon(currentMonthUrl);
      setCurrentMonthReport(currentMonthResponse.report);

      // Fetch previous month report
      const previousMonth = month === 1 ? 12 : month - 1;
      const previousYear = month === 1 ? year - 1 : year;
      const previousMonthUrl = `${ApiEndPoints("getReport")}?year=${previousYear}&month=${previousMonth}`;
      const previousMonthResponse = await allCoupon(previousMonthUrl);
      setPreviousMonthReport(previousMonthResponse.report);

    } catch (error) {
      console.error(error);
    }
  };

  // Calculate percentage difference
  const calculatePercentageDifference = (current: number, previous: number) => {
    if (previous === 0) return current > 0 ? 100 : 0;
    return ((current - previous) / previous) * 100;
  };

  const earningsDifference = calculatePercentageDifference(currentMonthReport.revenue, previousMonthReport.revenue);
  const salesDifference = calculatePercentageDifference(currentMonthReport.transactionsCount, previousMonthReport.transactionsCount);
  const userDifference= calculatePercentageDifference(currentMonthReport.usersCreated, previousMonthReport.usersCreated);
  useEffect(() => {
    fetchTransaction();
  }, []);

  return (
    <>
      <section className="">
        <Container>
          <div className="row justify-content-between align-items-center head-home py-3 px-2">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="pb-2">
                <h1>Today Orders</h1>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="">
                    <h2>Total Earnings: <span>${todayEarnings.toFixed(2)}</span></h2>
                  </div>
                  <div>
                    <h2>Total Orders: <span>{ogDatas.length}</span></h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table-outline">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="table-row">Customer ID</TableCell>
                    <TableCell className="table-row">Quantity</TableCell>
                    <TableCell className="table-row">Product Id</TableCell>
                    <TableCell className="table-row">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productDatas.map((row, i) => (
                    <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell component="th" scope="row" className="table-row">
                        {row.user.slice(-5)}
                      </TableCell>
                      <TableCell className="table-row">{row.lineItems.length}</TableCell>
                      <TableCell className="table-row">{row.lineItems.map((val: any) => val._id.slice(-5)).join(", ")}</TableCell>
                      <TableCell className="table-row">${row.amount.toFixed(2)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {productDatas.length === 0 && <h3 className="text-center py-3">No orders found!</h3>}
          </div>
          <div className="row pt-3 pb-3">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h2 className="title">Reports</h2>
              <div className="totalern-outline">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="total-box">
                      <h3>Total Earnings</h3>
                      <div className="total-box-color">
                        <h3 className="pb-3">${currentMonthReport.revenue.toFixed(2)}</h3>
                        <p>
                        <span>
                            {earningsDifference.toFixed()}%
                            {earningsDifference >= 0 ? 
                              <BiUpArrowAlt style={{ fontSize: "20px" }} /> : 
                              <BiDownArrowAlt style={{ fontSize: "20px" }} />}
                          </span> 
                          Vs Last Month
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="total-box">
                      <h3>Total Sales</h3>
                      <div className="total-box-color">
                        <h3 className="pb-3">{currentMonthReport.transactionsCount} Orders</h3>
                        <p>
                        <span>
                            {salesDifference.toFixed()}%
                            {salesDifference >= 0 ? 
                              <BiUpArrowAlt style={{ fontSize: "20px" }} /> : 
                              <BiDownArrowAlt style={{ fontSize: "20px" }} />}
                          </span>
                          Vs Last Month
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12 col-md-12 pt-2">
                    <div className="total-box">
                      <h3>Total Customers</h3>
                      <div className="total-box-color">
                        <h3 className="pb-3">{currentMonthReport.usersCreated} New Customers</h3>
                        <p>    <span>
                            {userDifference.toFixed()}%
                            {userDifference >= 0 ? 
                              <BiUpArrowAlt style={{ fontSize: "20px" }} /> : 
                              <BiDownArrowAlt style={{ fontSize: "20px" }} />}
                          </span> Vs Last Month</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h2 className="title">Coupon Applied</h2>
              <div className="totalern-outline">
                {isCoupon && <img className="img-fluid" src={`${API_URL}/public/images/${isCoupon}`} alt="Coupon" />}
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Home;
