import React from "react";
import { Container } from "react-bootstrap";
import { DatePicker, Space } from "antd";
import { BiUpArrowAlt } from "react-icons/bi";

const SalesReport = () => {
  const { RangePicker } = DatePicker;
  return (
    <>
      <section className="py-4">
        <Container>
          <div>
            <h2 className="pb-3">Sales Report</h2>
          </div>
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12">
              <div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="pb-3">
                      {" "}
                      <RangePicker className="datepicker" />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="py-3">
                      <div className="table-box p-3">
                        <table style={{ width: "100%" }}>
                          <tr>
                            <td className="total">Total Orders</td>
                            <td className="totalcon">200</td>
                          </tr>
                          <tr>
                            <td className="total py-2">Total Earnings </td>
                            <td className="totalcon">$ 150.00</td>
                          </tr>
                          <tr>
                            <td className="total">Total freebies download</td>
                            <td className="totalcon">75</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-0 d-flex justify-content-center align-items-center">
              <div>
                <h1 className="pt-5">Vs</h1>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12">
              <div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="pb-3">
                      {" "}
                      <RangePicker className="datepicker" />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="py-3">
                      <div className="table-box2 p-3">
                        <table style={{ width: "100%" }}>
                          <tr>
                            <td className="total">Total Orders</td>
                            <td className="totalcon">150</td>
                          </tr>
                          <tr>
                            <td className="total py-2">Total Earnings </td>
                            <td className="totalcon">$ 100.00</td>
                          </tr>
                          <tr>
                            <td className="total">Total freebies download</td>
                            <td className="totalcon">65</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h2 className="title py-3">Result</h2>
          </div>
          <div className="table-box px-3 py-4">
            <div className="row">
              <div className="col-lg-5 col-md-5 col-sm-12">
                <div>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td className="total">Total Orders</td>
                      <td className="totalcon">200</td>
                      <td>
                        <span className="percent-bg">
                          5%
                          <BiUpArrowAlt style={{ fontSize: "20px" }} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="total py-2">Total Earnings </td>
                      <td className="totalcon">$ 150.00</td>
                      <td>
                        <span className="percent-bg">
                          5%
                          <BiUpArrowAlt style={{ fontSize: "20px" }} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="total">Total freebies download</td>
                      <td className="totalcon">75</td>
                      <td>
                        <span className="percent-bg">
                          5%
                          <BiUpArrowAlt style={{ fontSize: "20px" }} />
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-0"></div>
              <div className="col-lg-5 col-md-5 col-sm-12">
                <div>    <table style={{ width: "100%" }}>
                    <tr>
                      <td className="total">Total Orders</td>
                      <td className="totalcon">150</td>
                      <td>
                        <span className="percent-bgdrop">
                          5%
                          <BiUpArrowAlt style={{ fontSize: "20px" }} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="total py-2">Total Earnings </td>
                      <td className="totalcon">$ 100.00</td>
                      <td>
                        <span className="percent-bgdrop">
                          5%
                          <BiUpArrowAlt style={{ fontSize: "20px" }} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="total">Total freebies download</td>
                      <td className="totalcon">65</td>
                      <td>
                        <span className="percent-bgdrop">
                          5%
                          <BiUpArrowAlt style={{ fontSize: "20px" }} />
                        </span>
                      </td>
                    </tr>
                  </table></div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default SalesReport;
