import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import {
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { message, Switch, Input, Checkbox } from "antd";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import ApiEndPoints from "../Api/Apiendpoints";
import { allCoupon, deleteBanner, updateProduct } from "../Api/Apicalls"; 
import moment from "moment";

const options = ["Edit", "Delete"];
const ITEM_HEIGHT = 48;

export default function Subadmin() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [admins, setAdmins] = useState<any>([]);
  const [opens, setOpens] = useState(false);
  const [editData, setEditData] = useState<any>({});
  const [permissions, setPermissions] = useState<string[]>([]);

  const handleEditOpen = (id: any) => {
    const adminFilter = admins.filter((val: any) => val._id === id);
    setEditData(adminFilter[0]);
    setPermissions(adminFilter[0].accessTabs || []);
    setOpens(true);
  };

  const handleEditClose = () => setOpens(false);

  const fetchAdmin = async () => {
    try {
      const response = await allCoupon(ApiEndPoints("getAllsubAdmin"));
      setAdmins(response.subadmins);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSwitchChange = async (adminId: string, checked: boolean) => {
    try {
      await updateProduct(ApiEndPoints("getAllsubAdmin"), adminId, {
        isActive: checked,
      });
      await fetchAdmin();
      message.success("Permission updated successfully");
    } catch (err) {
      message.error("Something went wrong!");
    }
  };

  const handleDeleteAdmin = async (id: any) => {
    try {
      await deleteBanner(ApiEndPoints("getAllsubAdmin"), id);
      await fetchAdmin();
      message.success("Sub Admin deleted successfully!");
    } catch (error) {
      message.error("Failed to delete Sub Admin!");
    }
  };

  const handleCheckboxChange = (item: string, checked: boolean) => {
    if (checked) {
      setPermissions([...permissions, item]);
    } else {
      setPermissions(permissions.filter((perm) => perm !== item));
    }
  };

  const handleUpdateSubAdmin = async () => {
    try {
      await updateProduct(ApiEndPoints("getAllsubAdmin"), editData._id, {
        name: editData.name,
        roleName: editData.roleName,
        email: editData.email,
        accessTabs: permissions,  
      });
      await fetchAdmin();
      message.success("Sub Admin updated successfully!");
      handleEditClose();
    } catch (error) {
      message.error("Failed to update Sub Admin!");
    }
  };

  useEffect(() => {
    fetchAdmin();
  }, []);

  return (
    <div>
      <section className="py-4">
        <Container>
          <div>
            <h2 className="title">Sub Admin</h2>
            <hr />
            <div className="d-flex justify-content-end pe-2">
              <Link to="/add-sub-admin" className="link">
                <span className="sub-but">Add New +</span>
              </Link>
            </div>

            <div className="py-3">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="table-row text-center">Name</TableCell>
                      <TableCell className="table-row text-center">Role</TableCell>
                      <TableCell className="table-row text-center">Email</TableCell>
                      <TableCell className="table-row text-center">Access</TableCell>
                      <TableCell className="table-row text-center">Permission</TableCell>
                      <TableCell className="table-row text-center">Password changed time</TableCell>
                      <TableCell className="table-row text-center">Edit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {admins.map((row: any, index: any) => (
                      <TableRow key={index}>
                        <TableCell className="table-row text-center">{row.name}</TableCell>
                        <TableCell className="table-row text-center">{row.roleName}</TableCell>
                        <TableCell className="table-row text-center">{row.email}</TableCell>
                        <TableCell className="table-row text-center">{row.accessTabs.join(", ")}</TableCell>
                        <TableCell className="table-row text-center">
                          <Switch
                            size="small"
                            checked={row.isActive}
                            onChange={(checked) => handleSwitchChange(row._id, checked)}
                          />
                        </TableCell>
                        <TableCell className="table-row text-center">
                          {row.passwordChangedAt ? moment(row.passwordChangedAt).format("YYYY-MM-DD") : "-"}
                        </TableCell>
                        <TableCell className="table-row text-center">
                          <IconButton onClick={handleClick}>
                            <MoreVertIcon />
                          </IconButton>
                          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                            <MenuItem onClick={() => handleEditOpen(row._id)}>
                              <EditOutlined /> Edit
                            </MenuItem>
                            <MenuItem onClick={() => handleDeleteAdmin(row._id)}>
                              <DeleteOutlined /> Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Container>
      </section>

      <Modal open={opens} onClose={handleEditClose}>
        <Box sx={{ ...style, overflowY: "auto", maxHeight: "90vh" }}>
          <Container>
            <h2 className="title">Edit Sub Admin</h2>
            <hr />
            <div className="add-sub">
              <label className="label py-2">Admin Name</label>
              <Input
                value={editData.name || ""}
                onChange={(e) => setEditData({ ...editData, name: e.target.value })}
              />
              <label className="label py-2">Admin Role</label>
              <Input
                value={editData.roleName || ""}
                onChange={(e) => setEditData({ ...editData, roleName: e.target.value })}
              />
              <label className="label py-2">Email</label>
              <Input
                value={editData.email || ""}
                onChange={(e) => setEditData({ ...editData, email: e.target.value })}
              />
              <label className="label py-3">Admin Access</label>
              <div className="combine-check">
                {[
                  "Home",
                  "Product",
                  "Custom Order",
                  "Freebies",
                  "Transaction",
                  "Banners",
                  "Sales Report",
                  "Customer",
                  "Coupons",
                  "Admin Access",
                  "New Product",
                  "Sub Admin",
                  "Custom Banner"
                ].map((item) => (
                  <div key={item}>
                    <Checkbox
                      checked={permissions.includes(item)}
                      onChange={(e) => handleCheckboxChange(item, e.target.checked)}
                    />
                    {item}
                  </div>
                ))}
              </div>

              <div className="cancel-but py-3">
                <Button onClick={handleEditClose}>Cancel</Button>
                <Button onClick={handleUpdateSubAdmin}>Update</Button>
              </div>
            </div>
          </Container>
        </Box>
      </Modal>
    </div>
  );
}
