import { PhoneOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Input, message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { IoMdPerson } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";

interface SignUpData {
  name: string;
  email: string;
  password: string;
  role: string;
  phone: number | null;
}

const SignUp = () => {
  const [signpdata, setSignupData] = useState<SignUpData>({
    name: "default",
    email: "",
    password: "",
    phone: null,
    role: "admin",
  });

  const [conformPass, setConformPass] = useState("");
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: string) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };

  const errormessage = (content: string) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };

  const handleSignup = () => {
    if (signpdata.email === "" && signpdata.password === "") {
      errormessage("Fill all required fields !");
    } else if (signpdata.password !== conformPass) {
      errormessage("Password and Conform password must be same");
    } else {
      try {
        const response = axios.post(
          "http://13.233.193.158:3000/api/user/auth/signup",
          signpdata,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        successmessage("Signup success");
        navigate("/");
      } catch (error) {
        errormessage("Something went wrong");
      }
    }
  };

  return (
    <>
    {contextHolder}
      <section>
        <Container>
          <div className="row" style={{ height: "100vh" }}>
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
              <div>
                <img
                  className="img-fluid"
                  width={"400px"}
                  src="/assets/signup.png"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
              <div className="py-3 login-box">
                <h2 className="title text-center">Welcome</h2>
                <p className=" text-center">Create your account</p>
                <label className="label py-2">Email id</label>
                <br />
                <Input
                  size="large"
                  suffix={<IoMdPerson />}
                  placeholder="Enter your email id"
                  style={{ height: "45px", width: "100%" }}
                  onChange={(e) =>
                    setSignupData({ ...signpdata, email: e.target.value })
                  }
                />
                <br />
                <label className="label py-2">Password</label>
                <br />
                <Input.Password
                  placeholder="Enter your password"
                  style={{ height: "45px", width: "100%" }}
                  onChange={(e) =>
                    setSignupData({ ...signpdata, password: e.target.value })
                  }
                />
                <br />
                <label className="label py-2">Confirm Password</label>
                <br />
                <Input.Password
                  placeholder="Enter your password"
                  style={{ height: "45px", width: "100%" }}
                  onChange={(e) => setConformPass(e.target.value)}
                />
                <br />
                <label className="label py-2">Phone Number (Optional)</label>
                <br />
                <Input
                  size="large"
                  placeholder="Enter your number"
                  style={{ height: "45px", width: "100%" }}
                  onChange={(e) => {
                    setSignupData({
                      ...signpdata,
                      phone: Number(e.target.value),
                    });
                  }}
                />
                <div className="text-center log-btn pt-3">
                  <Button variant="contained" onClick={handleSignup}>
                    Create
                  </Button>
                  <p>
                    Already have an account ? <Link to="/login">Login</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default SignUp;
