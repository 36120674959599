import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import { allCoupon, updateCoupon, updateProduct } from "../Api/Apicalls"; 
import ApiEndPoints from "../Api/Apiendpoints";
import { InboxOutlined } from "@mui/icons-material";
import { Upload, message } from "antd";
import type { RcFile } from "antd/es/upload";
import type { DatePickerProps } from "antd";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { API_URL } from "../Api/Config";

const { Dragger } = Upload;

const Coupon = () => {
  const [file, setFile] = useState<RcFile | null>(null);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [productDatas, setProductDatas] = useState<any[]>([]);
  const [selectedCoupon, setSelectedCoupon] = useState<any>({
    _id: "",
    image: "",
    couponCode: "",
    discountPercentage: "",
    expiryDate: "",
  });

  const fetchCoupon = async () => {
    try {
      const response = await allCoupon(ApiEndPoints("getCoupon"));
      setProductDatas(response.coupons);
      if (response.coupons.length > 0) {
        const coupon = response.coupons[0];
        setSelectedCoupon(coupon);
        setImageUrl(`${API_URL}/public/images/${coupon.image}`); 
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSelectedCoupon((prevCoupon: any) => ({
      ...prevCoupon,
      [name]: value,
    }));
  };

  const handleDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    setSelectedCoupon((prevCoupon: any) => ({
      ...prevCoupon,
      expiryDate: dateString,
    }));
  };

  const beforeUpload = (file: RcFile) => {
    setFile(file);
    setImageUrl(URL.createObjectURL(file)); 
    setSelectedCoupon((prevCoupon: any) => ({
      ...prevCoupon,
      image: file.name,
    }));
    return false; 
  };

  const applyCouponChanges = async (id: string) => {
    const formData = new FormData();
    formData.append("couponCode", selectedCoupon.couponCode);
    formData.append("discountPercentage", selectedCoupon.discountPercentage); 
    formData.append("expiryDate", selectedCoupon.expiryDate); 
    if (file) {
      formData.append("image", file);
    }
    try {
      const response = await updateCoupon(ApiEndPoints("getCoupon"), id, formData);
      message.success("Coupon updated successfully!");
      fetchCoupon(); 
    } catch (err) {
      console.error("Error updating coupon:", err);
      message.error("An error occurred while updating the coupon.");
    }
  };  
  

  useEffect(() => {
    fetchCoupon();
  }, []);

  return (
    <div>
      <section className="py-4">
        <Container>
          <h2 className="title">Coupons</h2>
          <div>
            <h3 className="banner-title">Coupon Banner</h3>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <label className="label py-2">Upload image</label>
                <div
                  style={{
                    gap: "10px",
                    border: "1px solid #FF518A",
                    width: "290px",
                    padding: "5px",
                  }}
                  className="d-flex"
                >
                  <div>
                    <Dragger {...{ name: "file", multiple: false, beforeUpload }}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined style={{ color: "#FF518A" }} />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                    </Dragger>
                  </div>
                </div>
                {imageUrl && <img src={imageUrl} alt="Coupon" style={{ width: "160px" }} />}
                
                <h3 className="banner-title">Coupon Code</h3>
                <input
                  placeholder="Enter your Coupon Code"
                  className="input-coupon"
                  name="couponCode"
                  value={selectedCoupon.couponCode}
                  onChange={handleInputChange}
                />

                <h3 className="banner-title">Discount Percentage</h3>
                <input
                  placeholder="Enter your Discount Percentage"
                  className="input-coupon"
                  name="discountPercentage"
                  value={selectedCoupon.discountPercentage}
                  onChange={handleInputChange}
                />

                <h3 className="banner-title">Valid Till</h3>
                <DatePicker
                  onChange={handleDateChange}
                  placeholder="Select valid till date"
                  className="input-coupon"
                  value={selectedCoupon.expiryDate ? dayjs(selectedCoupon.expiryDate) : null}
                />

                <div className="py-3">
                  <Button
                    variant="contained"
                    className="update-btn"
                    onClick={()=>applyCouponChanges(selectedCoupon._id)}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Coupon;
