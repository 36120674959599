import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Input, Select } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadFile, UploadProps } from "antd";
import { message, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Box, Button, Modal } from "@mui/material";
import { allbanner, forgetPass, postProducts } from "../Api/Apicalls";
import ApiEndPoints from "../Api/Apiendpoints";
import { useNavigate } from "react-router-dom";
import { blob } from "stream/consumers";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Editor } from "primereact/editor";

const { Option } = Select;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const { Dragger } = Upload;

function NewProduct() {
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [selectedValues, setSelectedValues] = useState<string | null>(null);
  const navigate = useNavigate();
  const [file, setFile] = useState<RcFile | null>(null);
  const [zip, setZip] = useState<RcFile | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [zipName, setZipName] = useState<string | null>(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  interface productData {
    category: string;
    name: string;
    price: string | number;
    description: string;
    sizes: string;
    stitchCount: string | number;
    colors: string | number;
    formats: string;
    quantity: string;
    slug: string;
    tags: string;
    metaContent: string;
    metaHeading: string;
    keywords: string;
    productTags: string;
  }
  const [productData, setProductData] = useState<productData>({
    category: "",
    name: "",
    price: "",
    description: "",
    sizes: "",
    stitchCount: "",
    colors: "",
    formats: "",
    quantity: "",
    slug: "",
    tags: "",
    metaContent: "",
    metaHeading: "",
    keywords: "",
    productTags: "",
  });

  const [tags, setTags] = useState<any>({
    tagName: "",
    tagValue: "",
  });

  // Validate image files
  const beforeUploadImage = (file: RcFile) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("Only image files are allowed!");
      return Upload.LIST_IGNORE;
    }
    const reader = new FileReader();
    reader.onload = () => setImageUrl(reader.result as string);
    reader.readAsDataURL(file);
    setFile(file);
    return false;
  };

  // Validate zip files
  const beforeUploadZip = (file: RcFile) => {
    const isZip =
      file.type === "application/zip" ||
      file.type === "application/x-zip-compressed";
    if (!isZip) {
      message.error("Only ZIP files are allowed!");
      return Upload.LIST_IGNORE;
    }
    setZipName(file.name);
    setZip(file);
    return false;
  };

  const uploadPropsImage: UploadProps = {
    name: "file",
    beforeUpload: beforeUploadImage,
  };

  const uploadPropsZip: UploadProps = {
    name: "file",
    beforeUpload: beforeUploadZip,
  };

  const handleSelectChange = (value: any, name: any) => {
    setProductData({ ...productData, [name]: value });
    setSelectedValue(value);
  };
  const [valTag, setValTag] = useState("");

  const handleSelectChangeM = (value: any, name: any, tags: any) => {
    setProductData({ ...productData, [name]: value });
    if (name === "productTags" && tags && tags.length > 0 && tags[0].label) {
      setValTag(tags[0].label);
    } else {
      setValTag("");
    }
    setSelectedValues(value);
  };

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: string) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };

  const errormessage = (content: string) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };

  // Handle form changes
  function handleInputChange(e: { target: { name: any; value: any } }) {
    const { name, value } = e.target;
    setProductData({ ...productData, [name]: value });
  }
  const [base64Images, setBase64Images] = useState<string[]>([]);


  const [uploadedImages, setUploadedImages] = useState<RcFile[]>([]);

  const maxImages = 6;
  const props: UploadProps = {
    name: "file",
    multiple: true,
    customRequest: async (options) => {
      const { file, onSuccess, onError } = options;
  
      const uploadedFile = file as RcFile;
  
      // Check if maximum limit is reached
      if (uploadedImages.length >= maxImages) {
        message.error(`You can only upload up to ${maxImages} images.`);
        onError?.(new Error("Upload limit reached"));
        return;
      }
  
      try {
        // Simulate upload or replace with your API call
        await new Promise((resolve) => setTimeout(resolve, 1000));
  
        // Update state with the new image
        setUploadedImages((prevImages) => [...prevImages, uploadedFile]);
  
        // Indicate successful upload
        onSuccess?.("ok");
        message.success(`${uploadedFile.name} uploaded successfully.`);
      } catch (error) {
        console.error(error);
        onError?.(error as Error);
        message.error(`${uploadedFile.name} upload failed.`);
      }
    },
    onChange(info) {
      const { fileList } = info;
  
      // Ensure the file list does not exceed the maximum limit
      if (fileList.length > maxImages) {
        message.error(`You can only select up to ${maxImages} images.`);
        return;
      }
  
      console.log("File list:", fileList);
    },
    onRemove(file) {
      // Remove the file from the state
      setUploadedImages((prevImages) =>
        prevImages.filter((f) => f.uid !== file.uid)
      );
      message.info(`${file.name} removed.`);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  // Handle product posting
  const handlePostProduct = async () => {
    if (selectedValue === "embroidery design") {
      // List of required fields
      const requiredFields = [
        { value: selectedValue || "", name: "category" },
        { value: productData.name, name: "name" },
        { value: productData.price, name: "price" },
        { value: productData.description, name: "description" },
        { value: productData.sizes, name: "sizes" },
        { value: productData.stitchCount, name: "stitchCount" },
        // { value: productData.colors, name: "colors" },
        { value: productData.formats, name: "formats" },
        { value: productData.quantity, name: "quantity" },
        { value: productData.slug, name: "slug" },
        // { value: productData.tags, name: "productTags" },
        // { value: productData.metaHeading, name: "metaHeading" },
        // { value: productData.metaContent, name: "metaContent" },
        // { value: file, name: "File" },
        { value: zip, name: "Zip" },
        { value: productData.keywords, name: "keywords" },
        { value: productData.productTags, name: "productTags" },
      ];
      // Check for missing fields
      const missingFields = requiredFields.filter(
        (field) =>
          !field.value ||
          (typeof field.value === "string" && field.value.trim() === "")
      );

      if (missingFields.length > 0) {
        const missingFieldNames = missingFields
          .map((field) => field.name)
          .join(", ");
        message.error(
          `Please fill out the following fields: ${missingFieldNames}`
        );
        return;
      }
    }
    // print design
    else {
      // List of required fields
      const requiredFields = [
        { value: selectedValue || "", name: "category" },
        { value: productData.name, name: "name" },
        { value: productData.description, name: "description" },
        { value: productData.slug, name: "slug" },
        // { value: productData.tags, name: "productTags" },
        // { value: productData.metaHeading, name: "metaHeading" },
        // { value: productData.metaContent, name: "metaContent" },
        // { value: file, name: "File" },
        { value: zip, name: "Zip" },
        { value: productData.keywords, name: "keywords" },
        { value: productData.productTags, name: "productTags" },
      ];
      // Check for missing fields
      const missingFields = requiredFields.filter(
        (field) =>
          !field.value ||
          (typeof field.value === "string" && field.value.trim() === "")
      );

      if (missingFields.length > 0) {
        const missingFieldNames = missingFields
          .map((field) => field.name)
          .join(", ");
        message.error(
          `Please fill out the following fields: ${missingFieldNames}`
        );
        return;
      }
    }

    try {
      // Create a new FormData object
      const formData = new FormData();
      // Append form fields to FormData
      formData.append("category", productData.category);
      formData.append("name", productData.name);
      formData.append("description", productData.description);
      formData.append("sizes", productData.sizes);
      formData.append("stitchCount", productData.stitchCount.toString());
      formData.append("price", productData.price.toString());
      formData.append("quantity", productData.quantity);
      formData.append("colors", productData.colors.toString());
      formData.append("formats", productData.formats);
      formData.append("slug", productData.slug);
      formData.append("tags", valTag);
      formData.append("keywords", productData.keywords);
      formData.append("productTags", productData.productTags);
      formData.append("metaHeading", "demo");
      formData.append("MetaContent", "demo");
     
      uploadedImages.forEach((file) => {
        formData.append("subimage", file); 
      });

      // if (file) {
      //   formData.append("image", file);
      // } else {
      //   console.error("No file selected");
      // }
      if (zip) {
        formData.append("zip", zip);
      } else {
        console.error("No file selected");
      }

      // Make the API request
      const result = await postProducts(ApiEndPoints("postProduct"), formData);

      successmessage("Product added successfully!");
      setProductData({
        category: "",
        name: "",
        price: "",
        description: "",
        sizes: "",
        stitchCount: "",
        colors: "",
        formats: "",
        quantity: "",
        metaHeading: "",
        metaContent: "",
        slug: "",
        tags: "",
        keywords: "",
        productTags: "",
      });

      setFile(null);
      setZipName(null);
      navigate("/products");
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errormessage(error.response.data.message);
      } else if (error.message) {
        errormessage(error.message);
      } else {
        errormessage("Something went wrong");
      }
    }
  };

  const handleTag = async () => {
    const data = {
      tagName: tags.tagName,
      tagValue: tags.tagName.toLocaleLowerCase().trim().replaceAll(" ", "-"),
    };
    try {
      const response = await forgetPass(ApiEndPoints("tag"), data);
      await getAllTags();
      setOpen(false);
      setTags({
        tagName: "",
        tagValue: "",
      });
      successmessage("Tag successfully added!");
    } catch (error: any) {
      message.error(error.response.message);
    }
  };

  const [allTag, setAllTag] = useState<any[]>([]);

  const [prodTags, setProdTags] = useState<any[]>([]);

  const getAllTags = async () => {
    try {
      const response = await allbanner(ApiEndPoints("tag"));
      setAllTag(response.tags);
      // await getAllProdTags()
    } catch (error: any) {
      errormessage(error.response.message);
    }
  };

  const getAllProdTags = async () => {
    try {
      const response = await allbanner(ApiEndPoints("addtags"));
      const formatProdtag = response.productTags.map((tag: any) => ({
        value: tag.tagName,
        label: tag.tagName,
      }));
      setProdTags(formatProdtag);
    } catch (error: any) {
      errormessage(error.response.message);
    }
  };

  useEffect(() => {
    getAllProdTags();
  }, []);

  useEffect(() => {
    getAllTags();
  }, []);

  useEffect(() => {
    const formattedTags = allTag.map((tag) => ({
      value: tag.tagValue,
      label: tag.tagName,
    }));
    setTags(formattedTags);
  }, [allTag]);

  return (
    <>
      {contextHolder}
      <section className="py-4">
        <Container>
          <div>
            <h2 className="title">New Products</h2>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 product-upload">
              <div>
                <label className="label py-2">
                  Product Name <span style={{ color: "red" }}>*</span>
                </label>
                <Input
                  placeholder=""
                  name="name"
                  onChange={handleInputChange}
                />
             
                <br />
                <label className="label py-2">
                  Images (Select 6 images) <span style={{ color: "red" }}>*</span>
                </label>
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined style={{ color: "#FF518A" }} />
                  </p>
                  <p className="ant-upload-text">Add images</p>
                </Dragger>
                <div className="pt-3">
                {uploadedImages.length>0&&uploadedImages.map((file, index) => (
              <img
                src={URL.createObjectURL(file)}
                alt={file.name}
                style={{ width: "100px", height: "100px" }}
              />
          ))}
                </div>
                <br />

                <label className="label py-2">
                  Upload zip File <span style={{ color: "red" }}>*</span>
                </label>

                <Dragger {...uploadPropsZip}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined style={{ color: "#FF518A" }} />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag ZIP file to this area to upload
                  </p>
                </Dragger>
                {zipName && <p>Selected ZIP file: {zipName}</p>}

                <label className="label py-2">
                  Price <span style={{ color: "red" }}>*</span>
                </label>
                <Input
                  name="price"
                  placeholder=""
                  onChange={handleInputChange}
                />
                <label className="label py-2">
                  Category <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  // name="category"
                  value={selectedValue}
                  style={{ width: "100%" }}
                  className="rg-input"
                  onChange={(value) => handleSelectChange(value, "category")}
                  options={[
                    { value: "print design", label: "Print design" },
                    { value: "embroidery design", label: "Embroidery design" },
                  ]}
                />

                {selectedValue === "embroidery design" && (
                  <div>
                    <div className="input-container">
                      <label className="input-label">
                        Sizes(Inches) <span style={{ color: "red" }}>*</span>
                      </label>
                      &nbsp;&nbsp;
                      <Input
                        placeholder="Eg 7 * 7"
                        className="rg-input"
                        name="sizes"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="input-container">
                      <label className="input-label">
                        Stitch Count <span style={{ color: "red" }}>*</span>
                      </label>
                      &nbsp;&nbsp;
                      <Input
                        placeholder="Eg 38982"
                        className="rg-input"
                        name="stitchCount"
                        onChange={handleInputChange}
                      />
                    </div>
                    {/* <div className="input-container">
                      <label className="input-label">
                        Colors <span style={{ color: "red" }}>*</span>
                      </label>
                      &nbsp;&nbsp;
                      <Input
                        placeholder="Eg 14"
                        className="rg-input"
                        name="colors"
                        onChange={handleInputChange}
                      />
                    </div> */}
                    <div className="input-container">
                      <label className="input-label">
                        Formats <span style={{ color: "red" }}>*</span>
                      </label>
                      &nbsp;&nbsp;
                      <Input
                        placeholder="Eg EXP,HUS,PES,DST,SEW,JEF,PCS,VP3,XXX,PDF"
                        className="rg-input"
                        name="formats"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="input-container">
                      <label className="input-label">
                        Quantity <span style={{ color: "red" }}>*</span>
                      </label>
                      &nbsp;&nbsp;
                      <Input
                        placeholder="Eg 1 Size"
                        name="quantity"
                        className="rg-input"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                )}

                <label className="label py-2">
                  Product Tags <span style={{ color: "red" }}>*</span>
                </label>
                <br />

                <Select
                  mode="multiple"
                  onChange={(value) => {
                    console.log("Selected Values:", value); 
                    setProductData({ ...productData, productTags: value });
                  }}
                  style={{ width: "100%" }}
                  className="rg-input"
                  options={prodTags}
                />

                {/* <label className="label py-2">
                  Tags <span style={{ color: "red" }}>*</span>
                </label>
                <br />

                <Select
                  // value={}
                  mode="multiple"
                  onChange={(value) =>
                    handleSelectChangeM(value, "tags", "tags")
                  }
                  style={{ width: "100%" }}
                  className="rg-input"
                  options={tags}
                /> */}
                <label className="label py-2">
                  Keywords
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Input
                  name="keywords"
                  placeholder="positive,energy"
                  onChange={handleInputChange}
                />
                <label className="label py-2">
                  URL format:("EX:buy-relatedword-print-design"){" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Input
                  name="slug"
                  placeholder=""
                  onChange={handleInputChange}
                />

                <label className="label py-2">
                  Product Description <span style={{ color: "red" }}>*</span>
                </label>

                <Editor
                  value={productData.description || ""}
                  onTextChange={(e) =>
                    e &&
                    setProductData({
                      ...productData,
                      description: e.htmlValue || "",
                    })
                  }
                  style={{
                    height: "320px",
                    lineHeight: "normal",
                    width: "100%",
                    wordWrap: "break-word",
                  }}
                />
              </div>
            </div>
            <div className="text-center py-3">
              <Button
                variant="contained"
                className="submit-btn"
                onClick={handlePostProduct}
              >
                Submit
              </Button>
            </div>
          </div>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h3 style={{ fontSize: "20px", fontWeight: 600 }}>
                  Add New Tag
                </h3>
                <IoIosCloseCircleOutline
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  onClick={handleClose}
                />
              </div>
              <input
                style={{
                  width: "100%",
                  padding: "5px",
                  border: "1px solid #000",
                  borderRadius: "5px",
                }}
                onChange={(e) => setTags({ ...tags, tagName: e.target.value })}
              />
              <div className="text-center pt-4">
                <Button
                  variant="contained"
                  className="submit-btn"
                  onClick={handleTag}
                >
                  Update
                </Button>
              </div>
            </Box>
          </Modal>
        </Container>
      </section>
    </>
  );
}

export default NewProduct;
