import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "../DashBoard/Home";
import Products from "../DashBoard/Products";
import CustomOrder from "../DashBoard/CustomOrder";
import Transcation from "../DashBoard/Transcation";
import NewProduct from "../DashBoard/NewProduct";
import Freebies from "../DashBoard/Freebies";
import SalesReport from "../DashBoard/SalesReport";
import Banners from "../DashBoard/Banners";
import Customers from "../DashBoard/Customers";
import NavBar from "../SideBar/NavBar";
import Login from "../DashBoard/Login";
import SignUp from "../DashBoard/SignUp";
import ForgetPass from "../DashBoard/ForgetPass";
import Header from "../DashBoard/Header";
import Subadmin from "../DashBoard/Sub-admin";
import Addsub from "../DashBoard/Addnew";
import Coupon from "../DashBoard/Coupens";
import SubadminLogin from "../DashBoard/SubadminLogin";
import CustomBanner from "../DashBoard/CustomBanner";
import Review from "../DashBoard/Review";
import Tags from "../DashBoard/Tags";
import Addnewtags from "../DashBoard/Addnewtags";
import EditTags from "../DashBoard/EditTags";

const Routing = () => {
  const [isUser, setIsUser] = useState(false);
  const [role, setRole] = useState(""); 

  useEffect(() => {
    const checkToken = () => {
      const adminToken = localStorage.getItem("dashtoken");
      const subAdminToken = localStorage.getItem("subadmintoken");

      if (adminToken) {
        setIsUser(true);
        setRole("admin");
        localStorage.setItem("role","admin")
      } else if (subAdminToken) {
        setIsUser(true);
        setRole("sub-admin");
        localStorage.setItem("role","subadmin")
      } else {
        setIsUser(false);
        setRole("");
      }
    };

    checkToken();

    const handleStorageChange = () => {
      checkToken();
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <BrowserRouter>
      {!isUser ? (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/sub-admin-login" element={<SubadminLogin />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/forget-password" element={<ForgetPass />} />
        </Routes>
      ) : role === "admin" ? (
        <>
          <NavBar/>
          <div style={{ marginLeft: "16%" }}>
            <Header />
            <div className="head-padd">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/products" element={<Products />} />
                <Route path="/custom-orders" element={<CustomOrder />} />
                <Route path="/freebies" element={<Freebies />} />
                <Route path="/new-product" element={<NewProduct />} />
                <Route path="/transaction" element={<Transcation />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/banners" element={<Banners />} />
                <Route path="/sales-reports" element={<SalesReport />} />
                <Route path="/sub-admin" element={<Subadmin />} />
                <Route path="/add-sub-admin" element={<Addsub />} />
                <Route path="/coupon" element={<Coupon />} />
                <Route path="/custom-banner" element={<CustomBanner />} />
                <Route path="/review" element={<Review />} />
                <Route path="/tags" element={<Tags />} />
                <Route path="/add-tags" element={<Addnewtags />} />
                <Route path="/edit-tags/:id" element={<EditTags />} />

              </Routes>
            </div>
          </div>
        </>
      ) : role === "sub-admin" ? (
        <>
          <NavBar/>
          <div style={{ marginLeft: "16%" }}>
            <Header />
            <div className="head-padd">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/products" element={<Products />} />
                <Route path="/custom-orders" element={<CustomOrder />} />
                <Route path="/freebies" element={<Freebies />} />
                <Route path="/new-product" element={<NewProduct />} />
                <Route path="/transaction" element={<Transcation />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/banners" element={<Banners />} />
                <Route path="/sales-reports" element={<SalesReport />} />
                <Route path="/sub-admin" element={<Subadmin />} />
                <Route path="/add-sub-admin" element={<Addsub />} />
                <Route path="/coupon" element={<Coupon />} />
                <Route path="/custom-banner" element={<CustomBanner />} />
                <Route path="/review" element={<Review />} />
                <Route path="/tags" element={<Tags />} />
                <Route path="/add-tags" element={<Addnewtags />} />
                <Route path="/edit-tags/:id" element={<EditTags />} />
              </Routes>
            </div>
          </div>
        </>
      ) : (
        <Navigate to="/" />
      )}
    </BrowserRouter>
  );
};

export default Routing;
