import { Button } from "@mui/material";
import { Input, message } from "antd";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { IoMdPerson } from "react-icons/io";
import { Link } from "react-router-dom";
import { allbanner, loginUser } from "../Api/Apicalls";
import ApiEndPoints from "../Api/Apiendpoints";

const SubadminLogin = () => {
  const [loginData, isLoginData] = useState({
    email: "",
    password: "",
  });

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: string) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };

  const errormessage = (content: string) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };

  //Login Function
  const handleLoginuser = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (loginData.email === "" || loginData.password === "") {
      errormessage("Need email and password");
    } else if (loginData.password.length < 6) {
      errormessage("Please check password and must be greater than 6letters");
    } else {
      try {
        const result = await loginUser(
          ApiEndPoints("subadminLogin"),
          loginData
        );
        if (result.token !== null) {
          localStorage.setItem("subadmintoken", result.token);
          window.dispatchEvent(new Event("storage"));
          successmessage("Login successfully done");
          window.location.reload();
        } else {
          errormessage("Only admins are allowed...");
        }
      } catch (error: any) {
        errormessage(
          error.response.data?.message || "An error occurred during login."
        );
      }
    }
  };

  return (
    <>
      {contextHolder}
      <section>
        <Container>
          <div className="row" style={{ height: "100vh" }}>
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
              <div>
                <img
                  className="img-fluid"
                  width={"400px"}
                  src="/assets/login.png"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
              <div className="py-3 login-box">
                <h2 className="title text-center">Welcome</h2>
                <p className=" text-center">
                  Enter your valid Email id and password
                </p>
                <label className="label py-2">Email id</label>
                <br />
                <Input
                  size="large"
                  suffix={<IoMdPerson />}
                  placeholder="Enter your email id"
                  style={{ height: "45px", width: "100%" }}
                  onChange={(e) =>
                    isLoginData({ ...loginData, email: e.target.value })
                  }
                />
                <br />
                <label className="label py-2">Password</label>
                <br />
                <Input.Password
                  placeholder="Enter your password"
                  style={{ height: "45px", width: "100%" }}
                  onChange={(e) =>
                    isLoginData({ ...loginData, password: e.target.value })
                  }
                />
                <br />
                <div className="text-end pt-2 pb-4">
                  <Link to="/forget-password" className="forgetpass">
                    Forget password
                  </Link>
                </div>
                <div className="text-center log-btn">
                  <Button variant="contained" onClick={handleLoginuser}>
                    Login
                  </Button>
                  <p>
                    Login as Admin ? <Link to="/">Admin</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default SubadminLogin;
