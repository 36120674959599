import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { MdArrowBack } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Editor } from "primereact/editor";
import ApiEndPoints from "../Api/Apiendpoints";
import { getbanner, updateProduct, updateTags } from "../Api/Apicalls";
import { message } from "antd";

interface TagData {
  tagName: string;
  tagContent: string;
  metaTitle: string;
  metaContent: string;
  date: Date;
}

const EditTags = () => {
  const [data, setData] = useState<TagData>({
    tagName: "",
    tagContent: "",
    metaTitle: "",
    metaContent: "",
    date: new Date(),
  });


  

  const { id } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const successMessage = (content: string) => {
    messageApi.open({
      type: "success",
      content,
    });
  };

  const errorMessage = (content: string) => {
    messageApi.open({
      type: "error",
      content,
    });
  };

  const updateTag = async () => {
    try {
      await updateTags(ApiEndPoints("addtags"), id, data);
      successMessage("Updated successfully");
      navigate("/tags");
    } catch (err) {
      errorMessage("Error updating product.");
      console.error("Error updating product:", err);
    }
  };
  
  const getTag = async () => {
    try {
      const response = await getbanner(ApiEndPoints("addtags"), id);
      setData(response.productTag);
    } catch (err) {
      errorMessage("Error fetching tag data.");
      console.error("Error fetching tag data:", err);
    }
  };

  useEffect(() => {
    getTag();
  }, []);

  return (
    <>
      {contextHolder}
      <section className="py-2">
        <Container>
          <div>
            <Link to="/tags" style={{ color: "#000", textDecoration: "none" }}>
              <span style={{ fontSize: "20px", fontWeight: 500 }}>
                <MdArrowBack /> Edit Tag
              </span>
            </Link>
          </div>
          <div className="tag-inbox">
            <label>
              Tag Name <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <input
              type="text"
              value={data.tagName}
              onChange={(e) => setData({ ...data, tagName: e.target.value })}
            />
            <br />
            <label>
              Tag Content <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <Editor
              value={data.tagContent}
              onTextChange={(e) =>
                e && setData({ ...data, tagContent: e.htmlValue || "" })
              }
              style={{
                height: "320px",
                lineHeight: "normal",
                width: "100%",
                wordWrap: "break-word",
              }}
            />
            <label>
              Meta Title <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <input
              type="text"
              value={data.metaTitle}
              onChange={(e) => setData({ ...data, metaTitle: e.target.value })}
            />
            <br />
            <label>
              Meta Content <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <textarea
              value={data.metaContent}
              onChange={(e) =>
                setData({ ...data, metaContent: e.target.value })
              }
            />
            <div className="text-center py-3">
              <Button variant="contained" className="addtagbtn" onClick={updateTag}>
                Update
              </Button>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default EditTags;
